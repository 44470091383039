import axios, { AxiosResponse } from 'axios';

import { IModelQueryParams, IModelsResponse, queryObjectToString, IModelArchitecturesAvailablePayload, IModelArchitecturesAvailableApiResponse, IModelLabelsAvailableApiResponse, UpdateModelApiResponse, UpdateModelApiRequestPayload, IModelAddPayload, GetModelDetailsAPiReqPayload, GetModelDetailsAPiResponse, DefaultDataSetCollectionAPIResponse, IModelCollectionUniqueDataPayload, UniqueModelCollectionVersionsApiResponse, UniqueModelCollectionArchitecturesApiResponse, UniqueModelCollectionStatusesApiResponse, UniqueModelCollectionTrainingDataSetsApiResponse, UniqueModelCollectionAccuracyStatusesApiResponse, IModelType, IModel, GetModelReferenceDataAPiReqPayload, IReferenceGroupState, IReferenceDataState,IAdditionalHandTrackingDataQueryParams ,AdditionalHandTrackingDataApiResponse, IModelNamesQueryParams, IModelNamesResponse} from '../common';
import ModelGroup from '../store/types/modelGroup.type';

import { API } from './API';
import { copyByValue } from './variableHelperService';

const URL_STRINGS = {
    MODEL: 'model',
    MODEL_GROUPS: 'modelGroups',
    COLLECTION: "collection",
    DATASET: "dataSet"
}

type GetModelGroupsApiRequestPayload = {
  projectId?: string,
  getModels?: "true",
  collectionsExist ?: boolean,
}
type GetModelGroupsApiResponse = (ModelGroup & {
  models?: IModel[]
})[]

class ModelService extends API {

  getProjectModels(
    data: IModelQueryParams
  ): Promise<AxiosResponse<IModelsResponse>> {
    return this.api.get(`${URL_STRINGS.MODEL}?${queryObjectToString(data)}`);
  }
  getProjectModelNames(
    data: IModelNamesQueryParams
  ): Promise<AxiosResponse<IModelNamesResponse>> {
    return this.api.get(`${URL_STRINGS.MODEL}/modelNames?${queryObjectToString(data)}`);
  }
  addModel(body: IModelAddPayload): Promise<AxiosResponse<unknown>> {
    return this.api.post(URL_STRINGS.MODEL, body);
  }
  deleteModel(body: {model: string}): Promise<AxiosResponse<unknown>> {
    return this.api.delete(URL_STRINGS.MODEL, { data: body });
  }
  getModelArchitecturesAvailable(
    data: IModelArchitecturesAvailablePayload
  ): Promise<AxiosResponse<IModelArchitecturesAvailableApiResponse>> {
    return this.api.get(
      `${URL_STRINGS.MODEL}/${data.modelId}/architecturesAvailable`
    );
  }

  
  getUniqueModelCollectionVersions(
    data: IModelCollectionUniqueDataPayload
  ): Promise<AxiosResponse<UniqueModelCollectionVersionsApiResponse>> {
    return this.api.get(
      `${URL_STRINGS.MODEL}/${data.modelId}/uniqueModelCollectionVersions`
    );
  }

  getUniqueModelCollectionArchitectures(
    data: IModelCollectionUniqueDataPayload
  ): Promise<AxiosResponse<UniqueModelCollectionArchitecturesApiResponse>> {
    return this.api.get(
      `${URL_STRINGS.MODEL}/${data.modelId}/uniqueModelCollectionArchitectures`
    );
  }

  getUniqueModelCollectionAccuracyStatuses(
    data: IModelCollectionUniqueDataPayload
  ): Promise<AxiosResponse<UniqueModelCollectionAccuracyStatusesApiResponse>> {
    return this.api.get(
      `${URL_STRINGS.COLLECTION}/${URL_STRINGS.MODEL}/${URL_STRINGS.MODEL}/${data.modelId}/uniqueAccuracyStatus`
    );
  }

  getUniqueModelCollectionTrainingDataSets(
    data: IModelCollectionUniqueDataPayload
  ): Promise<AxiosResponse<UniqueModelCollectionTrainingDataSetsApiResponse>> {
    return this.api.get(
      `${URL_STRINGS.MODEL}/${data.modelId}/uniqueModelCollectionTrainingDataSets`
    );
  }

  getUniqueModelCollectionStatuses(
    data: IModelCollectionUniqueDataPayload
  ): Promise<AxiosResponse<UniqueModelCollectionStatusesApiResponse>> {
    return this.api.get(
      `${URL_STRINGS.MODEL}/${data.modelId}/uniqueModelCollectionStatuses`
    );
  }
  getModelLabelsAvailable(data: {
    modelId: string;
  }): Promise<AxiosResponse<IModelLabelsAvailableApiResponse>> {
    return this.api.get(`${URL_STRINGS.MODEL}/${data.modelId}/labelsAvailable`);
  }
  getAllowUserToSetAnyLabelInModel(data: {
    modelId: string;
  }): Promise<AxiosResponse<boolean>> {
    return this.api.get(`${URL_STRINGS.MODEL}/${data.modelId}/getAllowUserToSetAnyLabel`);
  }
  updateModel(
    data: UpdateModelApiRequestPayload
  ): Promise<AxiosResponse<UpdateModelApiResponse>> {
    return this.api.put(URL_STRINGS.MODEL, data, 
      {
        headers: {'Content-Type': 'multipart/form-data'}
    });
  }
  updateModelReferenceData(
    data: FormData
  ): Promise<AxiosResponse<UpdateModelApiResponse>> {
    return this.api.put(`${URL_STRINGS.MODEL}/referenceData`, data, 
      {
        headers: {'Content-Type': 'multipart/form-data'}
    });
  }

  getModelReferenceData(
    data: GetModelReferenceDataAPiReqPayload
  ): Promise<AxiosResponse<IReferenceDataState []>> {
    return this.api.get(
      `${URL_STRINGS.MODEL}/${data.modelId}/referenceData`,
    );
  }

  getModelDetails(
    data: GetModelDetailsAPiReqPayload
  ): Promise<AxiosResponse<GetModelDetailsAPiResponse>> {
    const dataWithoutModelId = copyByValue(data);

    // @ts-ignore
    delete dataWithoutModelId?.modelId;

    return this.api.get(
      `${URL_STRINGS.MODEL}/${data.modelId}?${queryObjectToString(
        dataWithoutModelId
      )}`
    );
  }

  getCollectionDetails(
    collectionId: string
  ): Promise<AxiosResponse<any>> {

    return this.api.get(
      `${URL_STRINGS.COLLECTION}/${URL_STRINGS.DATASET}/${collectionId}`
    );
  }

  modelArchitectureRegister(data: any): Promise<AxiosResponse<any>> {
    return this.api.post(`${URL_STRINGS.MODEL}/architecture`, data);
  }
  
  modelArchitectureUpdate(data: any): Promise<AxiosResponse<any>> {
    return this.api.put(`${URL_STRINGS.MODEL}/architecture`, data);
  }

  modelArchitectureDelete(vm_name: string): Promise<AxiosResponse<any>> {
    return this.api.delete(`${URL_STRINGS.MODEL}/architecture/${vm_name}`);
  }

  getDefaultDataSetCollection(
    modelId: string
  ): Promise<AxiosResponse<DefaultDataSetCollectionAPIResponse>> {
    return this.api.get(
      `${URL_STRINGS.MODEL}/${modelId}?modelSelectQuery=defaultDataSetCollection`
    );
  }

  updateModelCollectionDefaultCheckPoint(
    payload : any
  ): Promise<AxiosResponse<any>> {
    return this.api.put(
      `collection/${URL_STRINGS.MODEL}/updateModelCollectionDefaultCheckPoint`, payload
    );
  }

  async getModelsByType(modelType: IModelType, projectId: string,): Promise<IModel[]> {
    const apiResponse = await this.api.get(`${URL_STRINGS.MODEL}/type/${modelType}?projectId=${projectId}`)
    return apiResponse.data
  }

  async getDataforTrainedModelQNAPopup(email: string,snoozeTime:number): Promise<any> {
    const apiResponse = await this.api.get(`${URL_STRINGS.COLLECTION}/${URL_STRINGS.MODEL}/collectionWithSeenTrainedNotificationAndPendingQnA?userEmail=${email}&snoozeTime=${snoozeTime}`)
    return apiResponse.data
  }
  
  async getModelGroups(payload: GetModelGroupsApiRequestPayload): Promise<GetModelGroupsApiResponse> {
    let queryParamsString = ""
    if (Object.keys(payload)?.length>0) {
      queryParamsString = queryObjectToString(payload)
    }
    const axiosResponse = await this.api.get(`${URL_STRINGS.MODEL_GROUPS}${queryParamsString ? `?${queryParamsString}` : ""}`);
    return axiosResponse.data
  }

  async updateModelGroup(modelGroupId: string, thingsToUpdate: {
    name?: ModelGroup["name"],
    colour?: ModelGroup["colour"],
    nodes?: ModelGroup["nodes"],
    edges?: ModelGroup["edges"]
  }): Promise<any> {
    const axiosResponse = await this.api.put(`${URL_STRINGS.MODEL_GROUPS}/${modelGroupId}`, thingsToUpdate);
    return axiosResponse.data
  }

  async createModelGroup(data: {
    name: ModelGroup["name"],
    projectId: ModelGroup["projectId"],
    colour: ModelGroup["colour"]
  }): Promise<ModelGroup> {
    const axiosResponse = await this.api.post(`${URL_STRINGS.MODEL_GROUPS}`, data);
    return axiosResponse.data;
  }
  async isModelGroupNameAlreadyTaken(data: {
    modelGroupName: ModelGroup["name"],
    projectId: ModelGroup["projectId"]
  }): Promise<ModelGroup> {
    const queryParamsString = queryObjectToString(data);
    const axiosResponse = await this.api.get(`${URL_STRINGS.MODEL_GROUPS}/modelGroupNameAlreadyTaken?${queryParamsString}`);
    return axiosResponse.data;
  }

  deleteModelGroup(idOfModelGroupToDelete: string): Promise<AxiosResponse<unknown>> {
    return this.api.delete(`${URL_STRINGS.MODEL_GROUPS}/${idOfModelGroupToDelete}`);
  }  
}

export default new ModelService();
